import React from 'react'
import './About.scss'
import team from '../../Images/team.svg'
import team2 from '../../Images/teammobile.svg'
import { motion } from "framer-motion"

function About() {
    return (
        <div id='about'>
            <div className='aboutGrid'>
                <div className='textContainer'>
                    <h1>About Us</h1>
                    <p><span style={{ color: '#8D68FF' }}>The fastest and most affordable</span> proxies on the market. Gravity Proxies was created with one goal in mind. To provide unbanned ultra fast proxies so you can cop during each and every release.</p>
                    <a href="#pricing"><div className="btn pricingButton text-nowrap">View Pricing</div></a>
                </div>
                <div className='teamContainer'>
                    <img src={team} alt="team" className='teamImage2' />
                    <motion.div
                        animate={{ rotate: [0, 360, 0] }}
                        transition={{ repeat: Infinity, duration: 15 }}
                        className="disableSafari"
                    >
                        <img src={team2} alt="team" className='teamImage' />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default About