import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Pricing2 from "../../Components/Pricing/Pricing2";
import "./PricingPage.scss";

function PricingPage() {
  return (
    <div id="pricePage">
      <Navbar homeNav="navbar navbar-expand-md navbar-dark bg-custom fixed-top" />
      <div className="spacer" />
      <Pricing2 />
    </div>
  );
}

export default PricingPage;
