import React from 'react'
import './Landing.scss'
import SneakerServer from '../../Images/sneakerserver.png'
import sneaker from '../../Images/sneaker.png'
import logobg from '../../Images/logobg.svg'
import lines from '../../Images/lines.svg'

import { motion } from "framer-motion"
import CountUp from 'react-countup';

function Landing() {
    return (
        <div id='landing'>
            <div className='gradientBackground' />
            <div className='textContainer'>
                <motion.div
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: .4 }}
                    className="pTop disableSafari"
                >
                    <h1>The <span className="textGradient">fastest proxies</span> in the galaxy</h1>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: .4, delay: .4 }}
                    className="pTop disableSafari"
                >
                    <p>We provide ultra premium proxies that make purchasing limited products easy.</p>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: .4, delay: .8 }}
                    className="pBottom disableSafari"
                >
                    <div className='btn-group gap-1'>
                        <a href="#pricing"><div className="btn buyButton text-nowrap">Buy Now</div></a>
                        <a href="#about"><div className="btn learnButton text-nowrap">Learn More</div></a>
                    </div>
                </motion.div>
            </div >
            <div className='absHolder'>
                <motion.div
                    animate={{
                        rotate: [0, 180, 0],
                    }}
                    transition={{ repeat: Infinity, duration: 20 }}
                    className="disableSafari"
                >
                    <img src={logobg} alt="logo" className='logobg' />
                </motion.div>
            </div>
            <img src={lines} alt="lines" className='lines' />
            <div className='imageContainer'>
                <motion.div
                    animate={{ y: [0, 20, 0] }}
                    transition={{ repeat: Infinity, duration: 3.5 }}
                    className="disableSafari"
                >
                    <img src={SneakerServer} alt="Sneaker Server" className='sneakerServer' />
                </motion.div>
                <motion.div
                    animate={{ y: [0, 30, 0] }}
                    transition={{ repeat: Infinity, duration: 3.5 }}
                    className="disableSafari"
                >
                    <img src={sneaker} alt="Sneaker" className='sneaker' />
                </motion.div>
            </div>
            <div className='statGrid'>
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 1.2 }}
                    className="disableSafari"
                >
                    <h2><CountUp start={0} end={1000} duration='1' delay={1.2} className='disableSafari' />+</h2>
                    <p>Customers</p>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 2.2 }}
                    className="disableSafari"
                >
                    <h2>$<CountUp start={0} end={500000} duration='1' delay={2.4} />+</h2>
                    <p>Money Spent</p>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 3.2 }}
                    className="disableSafari"
                >
                    <h2><CountUp start={0} end={4000} duration='1' delay={3.4} />+</h2>
                    <p>Active IPs</p>
                </motion.div>
            </div>
        </div >
    )
}

export default Landing