import React from 'react'
import './Soon.scss'
import logo from '../../Images/logo.svg'
import Navbar from '../../Components/Navbar/Navbar'

function NotFound() {
    return (
        <div>
            <Navbar
                homeNav='navbar navbar-expand-md navbar-dark bg-custom fixed-top'
            />
            <div id='soon'>
                <img src={logo} alt="Gravity Proxies" />
                <h1>404 - Page not found</h1>
                <p>Sorry this page does not exist, please return back to our main <a href='/'>site</a>.</p>
            </div>
        </div>
    )
}

export default NotFound