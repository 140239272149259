import React from 'react'
import './OtherIspPlans.scss'
import dataIcon from '../../Images/dataicon.svg';

 function OtherIspPlans() {
     return (
        <div id="otherIspPlans">
        <div className='otherPlans'>
            <div className='proxy-details'>
                <div className="proxy-info">
                    <img className="dataIcon" src={dataIcon} alt="datacenter-icon" />
                    <div className='proxies'>
                        <p className='otherPlans-desc'>Weekly ISP's</p>
                        <p className='otherPlans-price'>$25</p>
                    </div>
                </div>
                <div className='proxy-data'>5GB</div>
            </div>

          </div>
    </div>
  );
}

export default OtherIspPlans