import React, { useState, useEffect } from 'react'
import DashNavbar from '../../Components/DashNavbar/DashNavbar'
import IspDash from '../../Components/IspDash/IspDash'
import './Isp.scss';

function Isp() {

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 20) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.addEventListener('scroll', changeBackground);

    return (
        <div className="IspPage">
            <DashNavbar homeNav={navbar ? 'navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' : 'navbar navbar-expand-md navbar-dark bg-custom fixed-top'} />
            <IspDash />
        </div>

    )
}

export default Isp