import React from 'react'
import './Loader.scss'
import loader from '../../Images/loader.svg'

function Loader() {
    return (
        <div>
            <img src={loader} alt="loader" className='loader' />
        </div>
    )
}

export default Loader