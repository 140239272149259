import React, { createContext,useContext,useEffect,useState } from "react";

export const GlobalContext = createContext();
export const useGlobal =()=>{
    return useContext(GlobalContext)
}

export const GlobalProvider =({children})=>{
    const  [token, setToken] = useState(null);
    const  [username, setUser] = useState(null);
    const  [email, setMail] = useState(null);
    const  [avatar, setAvatar] = useState(null);
    const  [userId, setUserId] = useState(null);

    useEffect(()=>{
        const  jwt = localStorage.getItem("jwt");
        const  username = localStorage.getItem("username");
        const  email = localStorage.getItem("email");
        const  userID = localStorage.getItem("userId");
        const  avatar = localStorage.getItem("avatar");

        if(jwt){
            setToken(jwt);
        }
    
        setUser(username);
        setMail(email);
        setUserId(userID);
        setAvatar(avatar)

    },[])
    return (
        <GlobalContext.Provider value={{token, username, email, setToken, setUser, setMail,avatar,setAvatar,userId,setUserId}}>{children}</GlobalContext.Provider>
    )

}
