import React from 'react'
import dataIcon from '../../Images/dataicon.svg'
import './CurrentIspPlans.scss';

function CurrentIspPlans() {
    return (
        <div id="currentIspPlans">
            <div className='currentPlans'>
                <div className='currentPlans-heading'>
                    <img className="dataIcon" src={dataIcon} alt="datacenter-icon" />
                    <h1 className='heading-text'>Yeezy Supply Dailies</h1>
                </div>
                <div className='proxy-details'>
                    <div className='proxies'>
                        <p className='currentPlans-details'>Proxies</p>
                        <p className='currentPlans-info'>150</p>
                    </div>
                    <div className='order'>
                        <p className='currentPlans-details'>Order Date</p>
                        <p className='currentPlans-info'>Jun 2</p>
                    </div>
                    <div className='expiry'>
                        <p className='currentPlans-details'>Expiry Date</p>
                        <p className='currentPlans-info'>Aug 2</p>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default CurrentIspPlans
