import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { GlobalContext, useGlobal } from './Context/GlobalContext';
import Home from './Pages/Home/Home'
import Soon from './Pages/Soon/Soon'
import 'bootstrap/dist/css/bootstrap.min.css';
import PricePage from './Pages/PricingPage/PricingPage'
import NotFound from './Pages/Soon/NotFound'
import LoginRedirect from './Utils/LoginRedirect'
import IspPage from './Pages/Isp/Isp'
import HomeDashboard from './Pages/HomeDashboard/HomeDashboard'
function App() {
  const {token} = useGlobal(GlobalContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/purchase" element={<PricePage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/soon/" element={<Soon />} />
        <Route path="/connect/:discord/redirect" element={<LoginRedirect />} />
        {token&& <Route path="/dashboard/isp" element={<IspPage />} />}
        {token&& <Route path="/dashboard/home" element={< HomeDashboard />} />}
        {token&& <Route path="/dashboard/residential" element={<IspPage />} />}
        {token&&  <Route path="/dashboard/" element={<HomeDashboard />} />}

      </Routes>

    </BrowserRouter>
  );
}

export default App;