import React, { useState, useEffect } from 'react'
import './Soon.scss'
import logo from '../../Images/logo.svg'
import Navbar from '../../Components/Navbar/Navbar'

function Soon() {

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 20) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.addEventListener('scroll', changeBackground);

    return (
        <div>
            <Navbar
                homeNav={navbar ? 'navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' : 'navbar navbar-expand-md navbar-dark bg-custom fixed-top'}
            />
            <div id='soon'>
                <img src={logo} alt="Gravity Proxies" />
                <h1>Please check back soon</h1>
                <p>Our dashboard is currently under construction.</p>
            </div>
        </div>
    )
}

export default Soon