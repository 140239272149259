import React from 'react'
import './Features.scss'
import speeds from '../../Images/speeds.svg'
import support from '../../Images/support.svg'
import universal from '../../Images/universal.svg'
import rocket from '../../Images/rocket.svg'
import cloud1 from '../../Images/cloud1.png'
import cloud2 from '../../Images/cloud2.png'
import cloud3 from '../../Images/cloud3.png'
import star from '../../Images/star.svg'
import shootingstar from '../../Images/shootingstar.svg'

import { motion } from "framer-motion"

function Features() {
    return (
        <div id='features'>
            <div className='featureStyling'>
                <div className='sectionHeader'>
                    <h2>Features</h2>
                    <h1>What we have to offer</h1>
                    <p>We have a full range of different products to support your sneaker botting career from <span style={{ color: '#8D68FF' }}>ISPs, residential and gmails.</span></p>
                </div>
                <div className='cloudHolder'>
                    <motion.div
                        animate={{
                            opacity: [0, 1, 1, 0],
                        }}
                        transition={{ repeat: Infinity, duration: 2.5 }}
                        className="disableSafari"
                    >
                        <img src={star} alt="icon" className='star' />
                    </motion.div>
                    <motion.div
                        animate={{
                            opacity: [0, 1, 1, 0],
                            rotate: [10, 0, 0, -10],
                            x: [60, 0, 0, -40]
                        }}
                        transition={{ repeat: Infinity, duration: 5, repeatDelay: 1.5 }}
                        className="disableSafari"
                    >
                        <img src={shootingstar} alt="icon" className='shootingstar' />
                    </motion.div>
                    <motion.div
                        animate={{
                            x: [50, -50, 50],
                            y: [8, -8, 8, -8, 8]
                        }}
                        transition={{ repeat: Infinity, duration: 10 }}
                        className="disableSafari"
                    >
                        <img src={cloud1} alt="icon" className='cloud1' />
                    </motion.div>
                    <motion.div
                        animate={{
                            x: [50, -50, 50],
                            y: [6, -6, 6, -6, 6]
                        }}
                        transition={{ repeat: Infinity, duration: 10, delay: .8 }}
                        className="disableSafari"
                    >
                        <img src={cloud2} alt="icon" className='cloud2' />
                    </motion.div>
                    <motion.div
                        animate={{
                            x: [80, -50, 80],
                            y: [15, -15, 15, -15, 15]
                        }}
                        transition={{ repeat: Infinity, duration: 12, delay: .8 }}
                        className="disableSafari"
                    >
                        <img src={cloud3} alt="icon" className='cloud3' />
                    </motion.div>
                </div>
                <div className='featureGrid'>
                    <div>
                        <img src={speeds} alt="icon" className='featureIcon' />
                        <h3>Lightning Speeds</h3>
                        <p>Our Proxies are <span style={{ color: '#E556AC' }}>extremely fast</span> allowing your checkout times to be even faster! Try using Gravity proxies today.</p>
                    </div>
                    <div>
                        <img src={support} alt="icon" className='featureIcon' />
                        <h3>24/7 Support</h3>
                        <p>We provide <span style={{ color: '#42BECF' }}>support for any issues</span> that you may have. We have a <a href='https://discord.gg/XQ6QHtE5Nu'>Discord</a> with staff that are happy to assist you.</p>
                    </div>
                    <div>
                        <img src={universal} alt="icon" className='featureIcon' />
                        <h3>Universal Proxies</h3>
                        <p>Our proxies are <span style={{ color: '#ECB767' }}>fast & reliable</span>. We are compatible with Supreme, Shopify, Footsites, SNKRS, Adidas, and much more!</p>
                    </div>
                </div>
                <div>
                    <a href="#pricing"><div className="btn buyButton text-nowrap">Buy Now</div></a>
                </div>
                <div className='rocketHolder'>
                    <img src={rocket} alt="icon" className='rocket' />
                </div>
            </div>
        </div>
    )
}

export default Features