import React, { useState, useEffect } from 'react'
import DashNavbar from '../../Components/DashNavbar/DashNavbar'
import HomeDash from '../../Components/HomeDash/HomeDash'
import './HomeDashboard.scss';

function HomeDashboard() {

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 20) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.addEventListener('scroll', changeBackground);

    return (
        <div className="HomeDashboard">
            <DashNavbar homeNav={navbar ? 'navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' : 'navbar navbar-expand-md navbar-dark bg-custom fixed-top'} />
            <HomeDash />
        </div>

    )
}

export default HomeDashboard