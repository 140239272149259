import React from 'react'
import './Navbar.scss';
import { Link, NavLink } from "react-router-dom";
import logo from '../../Images/logo.svg'
import Scrollspy from 'react-scrollspy'
import { GlobalContext, useGlobal } from '../../Context/GlobalContext';

// const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
const backendUrl = `https://admin.gravityproxies.com`;
// const backendUrl = `http://localhost:1337`;


const Navbar = ({ homeNav }) => {
    const { token } = useGlobal(GlobalContext);
    return (
        <div id='navbar'>
            <nav className={homeNav}>
                <div className="container-fluid navbarWidth">
                    <a href='/'><img className="navlogo" src={logo} alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <Scrollspy
                            items={['landing', 'about', 'features', 'pricing', 'faq']}
                            currentClassName="current"
                            className="navbar-nav ms-auto pe-3 mt-1 mb-md-0 scrollspy" >
                            <li className="nav-item ms-md-3">
                                <a className="nav-link" href="/#">Home</a>
                            </li>
                            <li className="nav-item ms-md-3">
                                <a className="nav-link" href="/#about">About</a>
                            </li>
                            <li className="nav-item ms-md-3">
                                <a className="nav-link" href="/#features">Features</a>
                            </li>
                            <li className="nav-item ms-md-3">
                                <a className="nav-link" href="/#pricing">Pricing</a>
                            </li>
                            <li className="nav-item ms-md-3">
                                <a className="nav-link" href="/#faq">FAQ</a>
                            </li>
                        </Scrollspy>
                        {/* <form className="d-flex test">
                            {
                                token!==null? <Link to="/dashboard/"><div className="btn dashboardButton text-nowrap">Dashboard</div></Link>:          
                                <a href={`${backendUrl}/api/connect/discord`} ><div className="btn dashboardButton text-nowrap">Dashboard</div></a>

                            }
                        </form> */}
                        {/* <form className="d-flex test">
                            <Link to="/Soon/"><div className="btn dashboardButton text-nowrap">Dashboard</div></Link>
                        </form> */}
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar

// href={`${backendUrl}/api/connect/discord`} 
