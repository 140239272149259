import React, { useState, useEffect } from 'react'
import './Home.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Landing from '../../Components/Landing/Landing'
import About from '../../Components/About/About'
import Features from '../../Components/Features/Features'
import Pricing2 from '../../Components/Pricing/Pricing2'
import Faq from '../../Components/Faq/Faq'
import Footer from '../../Components/Footer/Footer'

function Home() {

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 20) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.addEventListener('scroll', changeBackground);

    return (
        <div>
            <Navbar
                homeNav={navbar ? 'navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' : 'navbar navbar-expand-md navbar-dark bg-custom fixed-top'}
            />
            <Landing />
            <About />
            <Features />
            <Pricing2 />
            <Faq />
            <Footer />
        </div>
    )
}

export default Home