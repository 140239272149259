import React from 'react'
import './Footer.scss'
import logo from '../../Images/logo.svg'

function Footer() {
    return (
        <div id='footer'>
            <div className='footerStyle'>
                <div>
                    <img src={logo} alt="logo" className='logo' />
                </div>
                <div>
                    <p className="footHead">Sections</p>
                    <a href="#"><p>Home</p></a>
                    <a href="#about"><p>About</p></a>
                    <a href="#features"><p>Features</p></a>
                    <a href="#pricing"><p>Pricing</p></a>
                </div>
                <div>
                    <p className="footHead">Socials</p>
                    <a href='https://discord.gg/XQ6QHtE5Nu'><p>Discord</p></a>
                    <a href='https://twitter.com/gravity_proxies'><p>Twitter</p></a>
                </div>
                <div>
                    <p className="footHead">Legal</p>
                    <p>Terms of Service</p>
                    <p>Privacy Policy</p>
                </div>
                <div className="alignRight">
                    <a href="#"><p className="toTop">Back to Top</p></a>
                </div>
            </div>
        </div>
    )
}

export default Footer