import React from 'react'
import './Faq.scss';
import Accordion from 'react-bootstrap/Accordion'

function Faq() {
    return (
        <div id="faq">
            <div className='faqHeading'>
                <h1>Frequently Asked Questions</h1>
                <p>Get answers to the most commonly asked questions.</p>
            </div>
            <Accordion className='mTop'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header className='faqHeader'>How do I receive my purchase?</Accordion.Header>
                    <Accordion.Body>
                    Via Email delivered at midnight EST timezone every night 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className='faqHeader'>Will I be automatically charged?</Accordion.Header>
                    <Accordion.Body>
                    All our plans are auto renewal and you'll be emailed a link on your receipt to access the dashboard to cancel your plans
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header className='faqHeader'>Do I need to authenticate my IP?</Accordion.Header>
                    <Accordion.Body>
                        No, all of our proxies are user:pass authenticated.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header className='faqHeader'>How can I contact Gravity Proxies for support?</Accordion.Header>
                    <Accordion.Body>
                        We have a <a href="https://discord.gg/XQ6QHtE5Nu">Discord</a> support server that you can join. Our staff are happy to assist you!
                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="4">
                    <Accordion.Header className='faqHeader'>Lorem ipsum dolor sit amet, consectetur adipiscing</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam velit itaque explicabo sunt ducimus. Cupiditate repellendus nemo iusto provident perspiciatis dolor, voluptate quidem tenetur labore cum id! Nihil, doloremque excepturi?
                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>
        </div>
    )
}

export default Faq
