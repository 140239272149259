import React, { useEffect, useState } from 'react'
import './IspDash.scss';
import CurrentIspPlans from '../../Components/CurrentIspPlans/CurrentIspPlans'
import OtherIspPlans from '../../Components/OtherIspPlans/OtherIspPlans'
import copyIcon from '../../Images/copyall.svg'
import axios from 'axios';
import Loader from '../Loader/Loader'

function IspDash() {

  const [proxies, setProxies] = useState([]);
  const [error, setError] = useState(null);
  const listProxies = proxies.proxyList
  const backendUrl = 'https://admin.gravityproxies.com'
  const id = localStorage.getItem("id");

  // Pull User Data
  useEffect(() => {
    async function fetchProxies() {
      try {
        await axios.get(`${backendUrl}/api/users/${id}`)
          .then(response => setProxies(response.data));
        // console.log(proxies)

      } catch (error) {
        setError(error)
      }
    }
    fetchProxies();
  }, [])

  /* const proxies = [
    '89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I 89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I  89.244.3698.3575:efkjfhsjs:fb93u4uhrif20I'
  ] */

  if (proxies) {
    return (
      <div id="IspDash">
        <div className="ISPContainer">
          <div className="disclaimer">
            <h1>Proxy Use Disclaimer</h1>
            <h2 className='disclaimer2'>Please be mindful of when running your proxies, do not throttle them.</h2>
            <p className='disclaimer3'>If we do notice abuse in delays or incorrect task to proxy ratios we will revoke your list without any refunds.</p>
          </div>
          <div className="current-plans">
            <h1>Current Plans</h1>
            <CurrentIspPlans />
          </div>
          <div className="proxy-list">
            <div className='proxy-description'>

              <div className="proxy-amount">
                <h1 className='proxy-title'>Proxies</h1>
                {/* <div className='vert-line' />
                <p className='proxy-num'>250</p> */}
              </div>

              <div className="copyAll">
                <img className="copyIcon" src={copyIcon} alt="Copy Icon" />
                <h1 className='copyList'>Copy List</h1>
              </div>

            </div>
            <textarea value={proxies ? listProxies : 'You currenty have no proxies to display'} disabled /> {/* onChange='' */}
          </div>

          <div className="other-isp-plans">
            <h1>Other ISP Plans</h1>
            <OtherIspPlans />
            <OtherIspPlans />
            <OtherIspPlans />
            <OtherIspPlans />
            <OtherIspPlans />

            <form className="purchase-button">
              <a href="#">
                <div className="dashboardButton">Purchase More</div>
              </a>
            </form>

          </div>
        </div>
      </div>
    );
  }

  return <Loader />

}

export default IspDash