import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Pricing.scss";
// const baseUrl = "http://localhost:1337";

const dummyProducts = [
  {
    attributes: {
      productTitle: "Nike Proxies",
      productSubTitle: "Europe",
      inStock: true,
      feature1: 'Unbanned on most sites',
      feature2: '99% Uptime',
      feature3: '30 days expiry date',
      feature4: 'Unlimited data',
      feature5: 'User:pass authenticated',
      plans: {
        data: [
          {
            attributes: {
              planPrice: 31.25,
              planLink: "https://buy.stripe.com/7sI4iw10SbPs1vG6q1",
              planAmount: "25 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 62.5,
              planLink: "https://buy.stripe.com/9AQ7uI390bPs7U4g0C",
              planAmount: "50 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 93.75,
              planLink: "https://buy.stripe.com/5kA02ggZQbPsdeoeWz",
              planAmount: "75 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 125,
              planLink: "https://buy.stripe.com/aEU7uIbFwf1E2zK01G",
              planAmount: "100 Proxies",
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      productTitle: "Nike Proxies",
      productSubTitle: "United States",
      inStock: true,
      feature1: 'Unbanned on most sites',
      feature2: '99% Uptime',
      feature3: '30 days expiry date',
      feature4: 'Unlimited data',
      feature5: 'User:pass authenticated',
      plans: {
        data: [
          {
            attributes: {
              planPrice: 25,
              planLink: "https://buy.stripe.com/cN28yM3902eS7U49C9",
              planAmount: "25 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 50,
              planLink: "https://buy.stripe.com/fZebKYgZQ4n05LW3ed",
              planAmount: "50 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 75,
              planLink: "https://buy.stripe.com/aEU3esfVM9Hka2ceWv",
              planAmount: "75 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 100,
              planLink: "https://buy.stripe.com/3cs6qEdNE9Hka2c8y8",
              planAmount: "100 Proxies",
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      productTitle: "Captcha Proxies",
      productSubTitle: "United States",
      inStock: false,
      feature1: 'Unbanned on most sites',
      feature2: '99% Uptime',
      feature3: '30 days expiry date',
      feature4: 'Unlimited data',
      feature5: 'User:pass authenticated',
      plans: {
        data: [
          {
            attributes: {
              planPrice: 25,
              planLink: "https://buy.stripe.com/cN28yM3902eS7U49C9",
              planAmount: "25 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 50,
              planLink: "https://buy.stripe.com/fZebKYgZQ4n05LW3ed",
              planAmount: "50 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 75,
              planLink: "https://buy.stripe.com/aEU3esfVM9Hka2ceWv",
              planAmount: "75 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 100,
              planLink: "https://buy.stripe.com/3cs6qEdNE9Hka2c8y8",
              planAmount: "100 Proxies",
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      productTitle: "Ticketmaster Proxies",
      productSubTitle: "United States",
      inStock: false,
      feature1: 'Unbanned on most sites',
      feature2: '99% Uptime',
      feature3: '30 days expiry date',
      feature4: 'Unlimited data',
      feature5: 'User:pass authenticated',
      plans: {
        data: [
          {
            attributes: {
              planPrice: 25,
              planLink: "https://buy.stripe.com/cN28yM3902eS7U49C9",
              planAmount: "25 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 50,
              planLink: "https://buy.stripe.com/fZebKYgZQ4n05LW3ed",
              planAmount: "50 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 75,
              planLink: "https://buy.stripe.com/aEU3esfVM9Hka2ceWv",
              planAmount: "75 Proxies",
            },
          },
          {
            attributes: {
              planPrice: 100,
              planLink: "https://buy.stripe.com/3cs6qEdNE9Hka2c8y8",
              planAmount: "100 Proxies",
            },
          },
        ],
      },
    },
  },
];

function Pricing() {
  const [products, setProduct] = useState(dummyProducts);

  function Product({
    productTitle,
    productSubTitle,
    inStock,
    feature1,
    feature2,
    feature3,
    feature4,
    feature5,
    feature6,
    plan,
  }) {
    const [plans] = useState(plan);
    const [amount, setAmount] = useState(plans[0].attributes.planPrice);
    const [planlink, setPlanLink] = useState(plans[0].attributes.planLink);
    useEffect(() => {
      if (amount === plans[0].attributes.planPrice) {
        setPlanLink(plans[0].attributes.planLink);
      }
    }, [amount, plans]);
    function HandleAmount(e) {
      setAmount(e.target.value);
      const comparePrice = plans.find((x) => {
        return String(x.attributes.planPrice) === e.target.value;
      });
      console.log("comparePrice", comparePrice);
      setPlanLink(comparePrice.attributes.planLink);
    }
    

    return (
      <div className="pricingCard">
        <div className="priceHeader">
          <h4>{productSubTitle}</h4>
          <h3>{productTitle}</h3>
        </div>
        <div className="priceBody">
          <li>{feature1}</li>
          <li>{feature2}</li>
          <li>{feature3}</li>
          <li>{feature4}</li>
          <li>{feature5}</li>
        </div>
        <div className="select-wrapper">
          <select value={amount} onChange={(e) => HandleAmount(e)}>
            <option value="" disabled>
              Select Amount
            </option>
            {plans.map((item, index) => (
              <option
                value={item.attributes.planPrice}
                key={index}
                defaultValue={amount}
              >
                {item.attributes.planAmount}
              </option>
            ))}
          </select>
        </div>
        <div className="text-center">
          <a
            href={amount ? planlink : ""}
            target="_blank"
            without
            rel="noreferrer"
          >
            <div
              className={
                inStock == true
                  ? "btn purchaseButton text-nowrap"
                  : "btn purchaseButton text-nowrap soldOut disabled"
              }
            >
              {inStock == true ? "Purchase - $" + amount : "Sold Out"}
            </div>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div id="pricing2">
      <div id="pricing">
        <div className="pricingContainer">
          <div className="sectionHeader">
            <h2>
              <span style={{ color: "#756E8D" }}>Your success starts here</span>
            </h2>
            <h1>Pricing</h1>
          </div>

          <div className="priceScroll" id="pricing">
            {products.map((item, index) => (
              <Product
                key={index}
                productTitle={item.attributes.productTitle}
                productSubTitle={item.attributes.productSubTitle}
                inStock={item.attributes.inStock}
                feature1={item.attributes.feature1}
                feature2={item.attributes.feature2}
                feature3={item.attributes.feature3}
                feature4={item.attributes.feature4}
                feature5={item.attributes.feature5}
                feature6={item.attributes.feature6}
                plan={item.attributes.plans.data}
              />
            ))}
          </div>

          <div className="text-center">
            <p className="termsText">
              By purchasing you agree to our{" "}
              <a href="/terms">terms of service</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
