import React from 'react'
import './DashNavbar.scss';
import { NavLink, useNavigate } from "react-router-dom";
import logodash from '../../Images/logodash.svg'
import profilepic from '../../Images/pfp.png'
import logout from '../../Images/logout.svg'
import { GlobalContext, useGlobal } from '../../Context/GlobalContext';
// import Scrollspy from 'react-scrollspy'

const DashNavbar = ({ homeNav }) => {
    const navigate = useNavigate();
    const { setToken, setUser, setMail,avatar,userId } = useGlobal(GlobalContext);
    function handleLogout(e) {
        e.preventDefault();
        localStorage.removeItem("jwt");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("avatar");

        setToken(null)
        setUser(null)
        setMail(null)
        navigate("/")
    }
    console.log(avatar)
    return (
        <div id='navbardash'>
            <nav className={homeNav}>
                <div className="container-fluid navbarWidth">
                    <a href="/"><img className="navlogo" src={logodash} alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul
                            className="navbar-nav me-auto ps-2 mt-1 mb-md-0" >
                            <li className="nav-item ms-md-3">
                                <NavLink className="nav-link" to="/dashboard/home">Home</NavLink>
                            </li>
                            <li className="nav-item ms-md-3">
                                <NavLink className="nav-link" to="/dashboard/isp">ISP</NavLink>
                            </li>
                            {/* <li className="nav-item ms-md-3">
                                <NavLink className="nav-link" to="/dashboard/residential">Residential</NavLink>
                            </li> */}
                        </ul>
                        <div className="dashboardButton text-nowrap">
                            <div>
                                <img className="navprofile" src={userId!==null && avatar !==null?`https://cdn.discordapp.com/avatars/${userId}/${avatar}.webp?size=128`:""} alt="pfp" />
                            </div>
                            <div className='logoutWrapper cursor-pointer' onClick={handleLogout}>
                                <img className="nav-logout" src={logout} alt="logout" />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default DashNavbar
