import React from 'react'
import './UpcomingReleases.scss';
import releaseImage from '../../Images/releases.png'
// import nike from '../../Images/nike.svg'
import moment from 'moment'

const UpcomingReleases = ({ siteLink, sneakerName, sneakerDate, siteName, sneakerImage }) => {

    // const backendUrl = `${process.env.REACT_APP_BACKEND_URL_TEST}`;
    const backendUrl = `https://admin.gravityproxies.com`;

    return (
        <div>
            <a id="releases" href={siteLink} target='_blank' className='noDecoration'>
                <img className="shoeImage" src={`${backendUrl + sneakerImage}`} alt="releases" />
                <div className="releaseDetails">
                    <h1 className='noDecoration'>{sneakerName}</h1>
                    <p className='noDecoration'>{moment(sneakerDate).format("MMMM Do")}</p>
                </div>
                {/* <h2 className='noDecoration'>{siteName}</h2> */}
                {/* <img src={nike} alt="nike" /> */}
            </a>
        </div>
    );
}

export default UpcomingReleases
