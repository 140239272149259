import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from '../Components/Loader/Loader'
import { GlobalContext, useGlobal } from '../Context/GlobalContext';

// const backendUrl = `${process.env.REACT_APP_BACKEND_URL_TEST}`;
const backendUrl = `https://admin.gravityproxies.com`;

// const backendUrl = `http://localhost:1337`;

const LoginRedirect = (props) => {
    const [text, setText] = useState('Loading...');
    const location = useLocation();
    console.log(location.search)
    const params = useParams();
    const navigate = useNavigate();
    const { setToken, setUser, setMail, setAvatar,setUserId } = useGlobal(GlobalContext);
    const query = new URLSearchParams(location.search);
    const accessToken = query.get('access_token')
    console.log(accessToken)
    useEffect(()=>{
        function fetchUser(){
            fetch('https://discord.com/api/users/@me', {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then(result => result.json())
                .then(response => {
                    const { avatar, id } = response;
                    localStorage.setItem('avatar', avatar);
                    localStorage.setItem('userId', id);

                    setAvatar(avatar)
                    setUserId(id)
                })
                .catch(console.error);
        }
        fetchUser()
    },[accessToken, setAvatar, setUserId])
    
    useEffect(() => {
        fetch(`${backendUrl}/api/auth/discord/callback${location.search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(response => { return response.json() })
            .then(res => {
                console.log(res)
                localStorage.setItem('jwt', res.jwt);
                localStorage.setItem('username', res.user.username);
                localStorage.setItem('email', res.user.email);
                localStorage.setItem('id', res.user.id);
                setToken(res.jwt)
                setUser(res.user.usernamet)
                setMail(res.user.email)
                setText('You have been successfully logged in. You will be redirected in a few moments...');
                navigate("../dashboard", { replace: true });
            })
            .catch(err => {
                console.log(err);
                setText('An error occurred, please see the developer console.')
            });
    }, [navigate, location.search, params.providerName, setToken, setUser, setMail]);

    return <Loader />
};

export default LoginRedirect;
